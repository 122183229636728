/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import history from '../utils/history';
import login from './modules/login';
import uiReducer from './modules/ui';
import initval from './modules/initForm';
import storyForm from './modules/storyForm';
import iteminitForm from './modules/iteminitForm';
import addcategoryForm from './modules/addCategoryForm';
import subcategoryinitForm from './modules/subcategoryinitForm';
import brandFrom from './modules/addBrandForm';
import attributesFrom from './modules/addAttributesFormForm';
import modifierForm from './modules/modifierForm';
import modifierSetForm from './modules/modifierSetForm';
import modifierItemForm from './modules/modifierItemForm';
import departmentform from './modules/addDepartmentForm';
import couponForm from './modules/addCouponForm';
import pretimesform from './modules/addPretimeForm';
import virtualcategory from './modules/addVirtualCategoryForm';
import daypartsForm from './modules/daypartsForm';
import userForm from './modules/userForm';
import promoForm from './modules/addPromotionForm';
import bannerForm from './modules/addBannerForm';
import contactUsForm from './modules/addContactUsForm';
import faqForm from './modules/addFaqForm';
import zoneForm from './modules/addZoneForm';
import membershipBenefitsForm from './modules/addMembershipBenefitsForm';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    login,
    ui: uiReducer,
    initval,
    modifierForm,
    storyForm,
    iteminitForm,
    addcategoryForm,
    subcategoryinitForm,
    brandFrom,
    attributesFrom,
    modifierSetForm,
    modifierItemForm,
    virtualcategory,
    departmentform,
    daypartsForm,
    pretimesform,
    userForm,
    couponForm,
    zoneForm,
    promoForm,
    bannerForm,
    contactUsForm,
    faqForm,
    membershipBenefitsForm,
    language: languageProviderReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
