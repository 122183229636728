export const CONFIG_HEADER = {
  'Content-Type': 'application/x-www-form-urlencoded',
   'Access-Control-Allow-Origin': '*',
};

export let TOKEN_HEADERS = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    "ngrok-skip-browser-warning": "true"
  },
};
export function getTokenUpdate(token) {
  return (TOKEN_HEADERS = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + token,
      "ngrok-skip-browser-warning": "true"
    },
  });
}

const API_URL = process.env.REACT_APP_API_URL;

//const API_URL = "https://ordersapi.enfmkt.com";

//const API_URL = "https://2f25-2405-205-c923-5997-5d4f-7c7f-e11e-dc46.in.ngrok.io";

const ADD_MIDDLE_WARE = '/admins';

export const LOGIN_URL = API_URL + ADD_MIDDLE_WARE + '/login';
export const ALL_USERS = API_URL + ADD_MIDDLE_WARE + '/allusers';
export const EDIT_USERS = API_URL + ADD_MIDDLE_WARE + '/edituser';

export const ALL_USERS_FILTER = API_URL + '/users/filter/';
//export const ALL_MODIFIERSET  =  API_URL + ADD_MIDDLE_WARE +  '/modifierset';
//export const ALL_MODIFIERSET_EXPORT  =  API_URL + '/exportcsv';
export const ALL_STORES = API_URL + ADD_MIDDLE_WARE + '/allstores';
export const ADD_STORES = API_URL + ADD_MIDDLE_WARE + '/addstores';
export const EDIT_STORES = API_URL + ADD_MIDDLE_WARE + '/editstores';

export const  ADD_STORE_IMAGE = API_URL + ADD_MIDDLE_WARE + '/store_image/';

export const ALL_CATEGORY = API_URL + '/category';
export const ALL_SUB_CATEGORES = API_URL + '/sub_categories';
export const ALL_SUB_CATEGORY = API_URL + '/subcategory';
export const ALL_SUB_CATEGORES_INSIDE = API_URL + '/subcategories';

export const ALL_UPDATE_ITEM = API_URL + '/updateitem';
export const ALL_PRODUCT = API_URL + ADD_MIDDLE_WARE + '/products';
export const GET_PRODCUT_BY_ID = API_URL + ADD_MIDDLE_WARE + '/product';
export const IMPORT_ITEMS = API_URL + ADD_MIDDLE_WARE + '/products/csv';
export const IMPORT_ORDERS = API_URL + ADD_MIDDLE_WARE + '/order_csv';


export const SEARCH_PRODUCT_NODE =
  API_URL + ADD_MIDDLE_WARE + '/searchproducts';
export const PRODUCT_MARKETING_FIELDS =
  API_URL + ADD_MIDDLE_WARE + '/product_marketing_fields';
export const CATEGORY_MARKETING_FIELDS =
  API_URL + ADD_MIDDLE_WARE + '/category_marketing_fields';
export const SUBCATEGORY_MARKETING_FIELDS =
  API_URL + ADD_MIDDLE_WARE + '/subcategory_marketing_fields';

export const ALL_GET_CATEGORIES = API_URL + ADD_MIDDLE_WARE + '/allcategories';
export const ALL_PRINTER_OPTIONS = API_URL + ADD_MIDDLE_WARE + '/allprinters';
export const ALL_BRAND_VALUE_LABLE = API_URL + ADD_MIDDLE_WARE + '/allvalues';
export const FILTER_BY_SUB_CATEGORY = API_URL + ADD_MIDDLE_WARE + '/getfilters';
export const FILTER_SEARCH_BRANDS = API_URL + ADD_MIDDLE_WARE + '/searchbrands';

export const ADD_PRODUCTS_ITEMS = API_URL + ADD_MIDDLE_WARE + '/additems';
export const EDIT_PRODUCTS_ITEMS = API_URL + ADD_MIDDLE_WARE + '/edititems';

export const ALL_BRANDS = API_URL + ADD_MIDDLE_WARE + '/allbrands';
export const ADD_BRAND = API_URL + ADD_MIDDLE_WARE + '/addbrand';
export const EDIT_BRAND = API_URL + ADD_MIDDLE_WARE + '/editbrand';

export const ADD_CATEGORIES = API_URL + ADD_MIDDLE_WARE + '/addcategories';
export const EDIT_CATEGORIES = API_URL + ADD_MIDDLE_WARE + '/editcategories';

export const ALL_MODIFIER_ITEMS =
  API_URL + ADD_MIDDLE_WARE + '/all_modifier_items';
export const ADD_MODIFIER_ITEMS =
  API_URL + ADD_MIDDLE_WARE + '/add_modifier_items';
export const EDIT_MODIFIER_ITEMS =
  API_URL + ADD_MIDDLE_WARE + '/edit_modifier_items';

export const ALL_MODIFIER_SETS =
  API_URL + ADD_MIDDLE_WARE + '/all_modifier_sets';
export const ADD_MODIFIER_SETS =
  API_URL + ADD_MIDDLE_WARE + '/add_modifier_sets';
export const EDIT_MODIFIER_SETS =
  API_URL + ADD_MIDDLE_WARE + '/edit_modifier_sets';

export const ALL_MODIFER_GROUP =
  API_URL + ADD_MIDDLE_WARE + '/all_modifier_groups';
export const ADD_MODIFER_GROUP =
  API_URL + ADD_MIDDLE_WARE + '/add_modifier_groups';
export const EDIT_MODIFER_GROUP =
  API_URL + ADD_MIDDLE_WARE + '/edit_modifier_groups';

export const ALL_ATTRIBUTES = API_URL + ADD_MIDDLE_WARE + '/allattributes';
export const ADD_ATTTRIBUTES = API_URL + ADD_MIDDLE_WARE + '/addattributes';
export const EDIT_ATTTRIBUTES = API_URL + ADD_MIDDLE_WARE + '/editattributes';


export const SEARCH_HOME_COLLECTION = API_URL + ADD_MIDDLE_WARE + '/search_home_collections';
export const GET_ALL_HOME_COLLECTION = API_URL + ADD_MIDDLE_WARE + '/home_collections';
export const ADD_EDIT_HOME_COLLECTION    = API_URL + ADD_MIDDLE_WARE + '/add_home_collections';



export const ALL_TAGS = API_URL + ADD_MIDDLE_WARE + '/alltags';
export const ADD_TAGS = API_URL + ADD_MIDDLE_WARE + '/addtags';
export const EDIT_TAGS = API_URL + ADD_MIDDLE_WARE + '/edittags';
export const DELETE_TAGS = API_URL + ADD_MIDDLE_WARE + '/delete_tag';


export const ALL_ZONES = API_URL + ADD_MIDDLE_WARE + '/allzones';
export const ADD_EDIT_ZONES = API_URL + ADD_MIDDLE_WARE + '/zone';


export const ALL_PROMOS = API_URL + ADD_MIDDLE_WARE + '/promotions';
export const ADD_PROMO = API_URL + ADD_MIDDLE_WARE + '/add_promotion';
export const EDIT_PROMO = API_URL + ADD_MIDDLE_WARE + '/edit_promotion';
export const DELETE_PROMO = API_URL + ADD_MIDDLE_WARE + '/delete_promotion';


export const DELETE_BANNER = API_URL + ADD_MIDDLE_WARE + '/delete_banner';

export const ADD_EDIT_BANNER = API_URL + ADD_MIDDLE_WARE + '/add_banner';

export const ALL_DEPARTMENTS = API_URL + ADD_MIDDLE_WARE + '/alldepartments';
export const ADD_DEPARTMENTS = API_URL + ADD_MIDDLE_WARE + '/add_department';
export const EDIT_DEPARTMENTS = API_URL + ADD_MIDDLE_WARE + '/edit_department';

export const ALL_DAYPARTS = API_URL + ADD_MIDDLE_WARE + '/alldayparts';
export const EDIT_DAYPARTS = API_URL + ADD_MIDDLE_WARE + '/edit_dayparts';
export const ADD_DAYPARTS = API_URL + ADD_MIDDLE_WARE + '/add_dayparts';

export const ALL_PRETIMES = API_URL + ADD_MIDDLE_WARE + '/allprep_time';
export const ADD_PRETIMES = API_URL + ADD_MIDDLE_WARE + '/add_prep_time';
export const EDIT_PRETIMES = API_URL + ADD_MIDDLE_WARE + '/edit_prep_time';

export const EXPORT_ITEMS = API_URL + ADD_MIDDLE_WARE + '/exportitems';
export const ALL_ORDERS = API_URL + ADD_MIDDLE_WARE + '/allorders';
export const ORDER_BY_ID = API_URL + ADD_MIDDLE_WARE + '/order';
export const PRINT_ORDER    =   API_URL + ADD_MIDDLE_WARE + '/print';


export const ALL_COUPONS = API_URL + ADD_MIDDLE_WARE + '/coupons';
export const ADD_COUPON = API_URL + ADD_MIDDLE_WARE + '/add_coupon';
export const EDIT_COUPON = API_URL + ADD_MIDDLE_WARE + '/edit_coupon';

export const ADD_USER_CSV = API_URL + ADD_MIDDLE_WARE + '/utilities/csv';
export const ADD_SIMILAR_ITEMS =
  API_URL + ADD_MIDDLE_WARE + '/utilities/similar';

export const ALL_CONTACT_US = API_URL + ADD_MIDDLE_WARE + '/allcontactustype';
export const ADD_CONTACT_US = API_URL + ADD_MIDDLE_WARE + '/add_contactustype';
export const EDIT_CONTACT_US =
  API_URL + ADD_MIDDLE_WARE + '/edit_contactustype';

export const ALL_FAQS = API_URL + ADD_MIDDLE_WARE + '/allfaqs';
export const ADD_FAQ = API_URL + ADD_MIDDLE_WARE + '/faqs';
export const DELETE_FAQ = API_URL + ADD_MIDDLE_WARE + '/delete_faq_item';

export const ALL_MEMBERSHIP_BENEFITS =
  API_URL + ADD_MIDDLE_WARE + '/allmemberbenefits';
export const ADD_MEMBERSHIP_BENEFITS =
  API_URL + ADD_MIDDLE_WARE + '/add_memberbenefits';
export const EDIT_MEMBERSHIP_BENEFITS =
  API_URL + ADD_MIDDLE_WARE + '/edit_memberbenefits';
export const ALL_MEMBERSHIP_TYPES =
  API_URL + ADD_MIDDLE_WARE + '/all_membership_types';

export const ALL_DELETED_USERS = API_URL + ADD_MIDDLE_WARE + '/deleted_users';
